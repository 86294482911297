<template>
  <div>
    <div class="top-banner">
      <div class="bg"></div>
      <div class="ban">
        <div class="title-big">American Delivery Service </div>
        <div class="title-small">
          <div>Experience hassle-free, timely delivery of your products.
          </div>
          <div>We allow you to add at most 200 packages in one order. </div>
        </div>
      </div>
    </div>
    <div class="about-us">About Us</div>
    <div class="about-item">
      <img :src="require('../../../assets/images/home/hello.png')" />
      <div class="about-des" style="padding-left:80px;">
        <div class="til">Welcome</div>
        <p>
          Welcome to our shipping courier! We are a reliable and efficient logistics company dedicated to providing
          top-quality shipping services for domestic businesses and individuals.<br />
        </p>
        <p style="padding-top:20px;">
          With a focus on fast and secure delivery, we have established ourselves as a leading provider of shipping
          services. Our experienced team of professionals works tirelessly to ensure that your shipments arrive on
          time and
          in excellent condition.
        </p>
      </div>
    </div>
    <div class="about-item">
      <div class="about-des" style="padding-right:80px;">
        <div class="til">Professional Services</div>
        <p>
          At our shipping courier, we offer a wide range of shipping and courier services to meet your specific
          needs, including express shipping, standard delivery, same-day delivery, overnight and more. Our
          state-of-the-art
          tracking technology allows you to monitor the progress of your shipment every step of the way, giving you
          peace of
          mind and ensuring that you always know where your package is.
        </p>
      </div>
      <img :src="require('../../../assets/images/home/service.png')" />
    </div>
    <div class="about-item" style="padding-bottom:100px;">
      <img :src="require('../../../assets/images/home/term.png')" />
      <div class="about-des" style="padding-left:80px;">
        <div class="til">Our Team</div>
        <p>
          Our commitment to exceptional customer service sets us apart from the competition. Our friendly and
          knowledgeable
          team is available to assist you with any questions or concerns you may have about your shipments.
        </p>
        <p style="padding-top:12px;">
          If you have any questions or would like to schedule a shipment with us, please don't hesitate to contact us. You
          can also visit our homepage to learn more about our services and to request a quote.
        </p>
      </div>
    </div>
    <div class="over-bottom">
      Thank you for considering our shipping courier for your shipping needs. We are
      committed to providing you
      with the
      best possible shipping experience, and we look forward to hearing from you soon.
    </div>
  </div>
</template>

<script setup>

</script>

<style lang="less" scoped>
.top-banner {
  box-sizing: border-box;
  position: relative;
  height: 538px;
  overflow: hidden;

  .bg {
    background-image: url('../../../assets/images/home/cangku.png');
    background-size: cover;
    background-position: 50% 50%;
    height: 540px;
    background-clip: content-box;
    filter: blur(2px);
  }

  .ban {
    width: 700px;
    position: absolute;
    z-index: 9;
    top: 50%;
    right: 50%;
    transform: translate(50%, -70%);
  }

  .title-big {
    font-weight: 500;
    font-size: 56px;
    color: #fff;
    text-align: center;
    line-height: 64px;
    padding-top: 166px;
  }

  .title-small {
    margin-top: 28px;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    line-height: 28px;
    color: #fff;
  }
}

.about-us {
  text-align: center;
  padding-top: 100px;
  font-weight: 300;
  font-size: 56px;
  line-height: 53px;
  color: #333333;
}

.about-item {
  display: flex;
  justify-content: space-between;
  width: 1300px;
  margin: 0 auto;
  padding: 140px 40px 0 40px;
  box-sizing: border-box;

  img {
    border-radius: 8px;
  }
}

.about-des {
  height: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .til {
    font-weight: 300;
    font-size: 40px;
    margin-bottom: 16px;
    color: #333;
  }

  p {
    font-weight: 400;
    font-size: 24px;
    line-height: 1.8;
    color: #7D7D7D;
    text-align: left;
    margin: 0;
  }
}

.over-bottom {
  font-family: 'PingFang SC';
  font-weight: 400;
  font-size: 32px;
  width: 1250px;
  margin: 0 auto;
  color: #333333;
  padding: 0 20px 68px 20px;
}
</style>
